import createGlobe from 'cobe';

class Earth {

    #devicePixelRatio: number;
    #phi: number;
    #canvasElement: HTMLElement;

    rotationSpeed: number;

    constructor(canvasElement: HTMLElement) {

        this.#devicePixelRatio = 2;
        this.#phi = 3;
        this.rotationSpeed = 0.001;
        this.#canvasElement = canvasElement;
    }

    get #width() {
        return this.#canvasElement.offsetWidth * this.#devicePixelRatio;
    }

    get #height() {
        return this.#canvasElement.offsetHeight * this.#devicePixelRatio;
    }

    get #rotation () {

        this.#phi += this.rotationSpeed;

        return this.#phi;
    }

    increaseRotationSpeed () {

        console.log(this.rotationSpeed);

        this.rotationSpeed += 0.01;
    }

    render () {

        createGlobe(
            this.#canvasElement,
            {
                devicePixelRatio: this.#devicePixelRatio,
                width: this.#width,
                height: this.#height,
                phi: 0,
                theta: 0.235,
                dark: 1,
                diffuse: 1.2,
                mapSamples: 23000,
                mapBrightness: 6,
                baseColor: [0, .9, 1],
                markerColor: [1, 0, 0],
                glowColor: [1, 1, 1],
                markers: [
                    { location: [54.3414884, 9.8455005], size: 0.05 },
                ],
                onRender: (state) => {
                    state.phi = this.#rotation;
                    state.width = this.#width;
                    state.height = this.#height;
                }
            }
        );
    }
}

const initialize = () => {

    const canvasId = 'globe';

    const canvasElement = document.getElementById(canvasId);

    if (canvasElement === null) {
        throw new Error(`Expects canvas element with id '${canvasId}'.`);
    }

    const earth = new Earth(canvasElement);
    earth.render();

    document.addEventListener('click', earth.increaseRotationSpeed.bind(earth));
}

document.addEventListener('DOMContentLoaded', initialize);
